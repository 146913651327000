import './breadcrumb.scss';
import { gsap } from 'gsap';

class Breadcrumb {
    constructor ($element) {
        this.$breadcrumb = $element;
        this.$breadcrumbArray = $element.querySelectorAll('[data-breadcrumb="item"]');
        this.$truncatedItems = null;
        this.$firstClick = true;
    }

    initialize () {
        this.truncateItem();
        this.checkSize();
        [1280, 320].forEach((bp) => {
            window.mediaMatchHandler[bp].addEventListener('change', () => {
                this.checkSize();
            });
        });
    }

    checkSize () {
        if (window.mediaMatchHandler[1280].matches) {
            this.$firstClick = false;
            this.untruncateItems();
        } else {
            // truncate on resize
            for (let i = 0; i < this.$breadcrumbArray.length; i++) {
                if ((i > 0 && i < this.$breadcrumbArray.length)) {
                    const item = this.$breadcrumbArray[i];
                    item.classList.add('is--truncated');
                }
            }

            // set truncated with on resize
            if (!this.$firstClick) {
                this.$truncatedItems.forEach((item) => {
                    item.style.width = 'auto';
                });
            }

            // allow animation again
            this.$firstClick = true;
        }
    }

    truncateItem () {
        for (let i = 0; i < this.$breadcrumbArray.length; i++) {
            if ((i > 0 && i < this.$breadcrumbArray.length)) {
                const item = this.$breadcrumbArray[i];
                item.setAttribute('data-breadcrumb-width', item.clientWidth);
                item.classList.add('is--truncated');
                item.querySelector('[data-breadcrumb="item-text"]').insertAdjacentHTML('beforeend', '<i class="breadcrumb-item__text-truncated">...</i>');
            }
        }

        this.$truncatedItems = this.$breadcrumb.querySelectorAll('.is--truncated');
        this.$breadcrumb.classList.remove('is--hidden');
        if (this.$truncatedItems.length > 0) {
            this.setEvents();
        }
    }

    setEvents () {
        for (let i = 0; i < this.$truncatedItems.length; i++) {
            const item = this.$truncatedItems[i];
            item.addEventListener('click', (e) => {
                if (this.$firstClick) {
                    e.preventDefault();
                    this.untruncateItems();
                }

                this.$firstClick = false;
            });
        }
    }

    untruncateItems () {
        this.$truncatedItems.forEach((element) => {
            const elementWidth = parseInt(element.getAttribute('data-breadcrumb-width'));

            if (this.$firstClick) {
                gsap.fromTo(element, { width: 10 }, {
                    width: elementWidth + 5,
                    duration: 0.5,
                    onComplete: () => {
                        // console.log('fertig');
                    }
                });
            }
            element.classList.remove('is--truncated');
        });
    }
}

export { Breadcrumb };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$breadcrumb = $context.querySelectorAll('[data-breadcrumb="root"]');

        for (let i = 0; i < $$breadcrumb.length; i++) {
            const $breadcrumb = new Breadcrumb($$breadcrumb[i]);
            $breadcrumb.initialize();
        }
    }
});
